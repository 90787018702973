.menu {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  padding: 1rem;
  width: 18rem;
  border-right: 1px solid rgba(255, 255, 255, 0.3);
  color: white;
}

.menu__title {
  color: rgb(204, 0, 0);
  font-family: 'Luckiest Guy';
  font-size: 60px;
  margin: 0;
  padding: 0;
  filter: drop-shadow(2px 2px 0 white);
}

.menu__subtitle {
  margin-bottom: 0.5rem;
  font-size: 14px;
}

.menu__section-title {
  font-size: 20px;
}

.menu__section {
  margin: 2rem 0;
}

.menu__choose-files {
  margin: 0.5rem 0;
}

.menu__list {
  list-style-type: none;
  padding: 0;
  margin: 0.5rem 0;
}

.menu__item {
  color: white;
  margin-bottom: 0.3rem;
}

.menu__item-button {
  border: none;
  background: none;
  color: white;
  line-height: normal;
  padding: 0.5rem;
  width: 100%;
  text-align: left;
  cursor: pointer;
}

.menu__day-button {
  border: none;
  background: none;
  color: rgb(123, 123, 123);
  font-size: 12px;
  line-height: normal;
  width: 100%;
  text-align: left;
  cursor: pointer;
  padding: 0.25rem;
}

.menu__item--selected {
  background: rgb(204, 0, 0);
}

.menu__error {
  color: rgb(123, 123, 123);
  font-size: 12px;
  margin-top: 0.5rem;
}

.menu__date {
  color: rgb(123, 123, 123);
  font-size: 12px;
  margin-top: 1rem;
}