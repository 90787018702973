.detail {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: calc(100vw - 20rem);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  color: white;
}

.detail__moment {
  width: calc((100vw - 20rem) / 2);
  height: calc(50vh);
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(18, 19, 26);
}

.detail_moment-view {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  font-size: 10px;
  padding: 0.25rem;
}

.detail__video {
  width: 100%;
  height: 100%;
}